.headerContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 30px;
    width: 100%;
    height: 100%;
}
.header {
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.logo {
    font-size: 32px;
    color: #efefef;
    text-decoration: none;
}

.header .logout {
    margin-left: auto;
    color: #ddd;
    text-decoration: underline;
    cursor: pointer;
}

.campaignNameOnHeader {
    color: #efefef;
    margin-left: 40px;
    font-size: 32px;
}

.campaignNameOnHeader:before {
    content: ">";
    margin-right: 20px;
}


/*
** Mobile
*/

@media screen and (max-width: 768px) {
    a.logo {
        font-size: 20px;
    }

    .header .logout {
        text-align: right;
    }

    .campaignNameOnHeader {
        display: none;
    }
}
