.react-tabs {
    width: 100%
}

.react-tabs__tab-list {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 0;
    width: 100%;
}

.react-tabs__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 30px;
    margin-right: 30px;
    cursor: pointer;
    color: #efefef;
    font-size: 1.5em;
    font-weight: bold;
    position: relative;
    border-radius: 12px 12px 0px 0px;
    padding: 20px 20px;
}

.react-tabs__tab--selected {
    background: rgba(255,255,255,0.1)
}

.react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
}

.react-tabs__tab:focus {
    outline: none;
}

.react-tabs__tab-panel {
    display: none;
    width: 100%;
    background: rgba(255,255,255,0.1);
    padding: 20px;
    border-radius: 12px 12px 12px 12px;
}

.react-tabs__tab-panel:first-of-type {
    border-radius: 0px 12px 12px 12px;
}

.react-tabs__tab-panel--selected {
    display: block;
}


.removeGroupFromAdsList {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
}

.removeGroupFromAdsList img {
    display: block;
    width: 16px;
    height: 16px;
    margin-left: 20px;
}


/*
** Tablet
*/

@media screen and (max-width: 1024px) {

    .react-tabs__tab {
        font-size: 16px;
    }
}

/*
** Mobile
*/

@media screen and (max-width: 768px) {
    .react-tabs__tab {margin-right: 10px;padding: 12px 20px;}
}
