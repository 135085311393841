.mainPage .container {
    height: 100%;
}

.singleColumnOnMainPage {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 30%;
    padding: 20px;
    height: 90%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: rgba(255,255,255,0.1);
    border-radius: 12px;
}

.userPage.mainPage .container {
    justify-content: center;
}

.emptyListMessage {
    color: #ddd;
    text-align: center;
    font-size: 18px;
    padding: 0px 20px;
    width: 100%
}

.listElement {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 12px;
    background: rgba(255,255,255,0.1);
    padding: 20px;
    color: #ddd;
    transition: .1s;
}

.listElement:hover {
    background: rgba(255,255,255,0.2);
}

.listElement.active {
    background: rgba(255,255,255,0.3);
}


/*
** Tablet
*/

@media screen and (max-width: 1024px) {
    .mainPage .container {
        height: auto;
    }

    .singleColumnOnMainPage {
        width: 47%;
        height: 400px;
    }

    .userlist.singleColumnOnMainPage {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }
}

/*
** Mobile
*/

@media screen and (max-width: 768px) {
    .singleColumnOnMainPage {
        width: 100%;
        margin-right: 0px!important;
        height: 500px;
        margin-bottom: 20px;
    }
}
