.singleAccount {
    cursor: pointer;
}

.singleAccount.listElement {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    position: relative;
}

.accountInfo {
    width: 70%;
    padding-right: 15px;
    box-sizing: border-box;
}

.accountImages {
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.accountImages img {
    display: block;
    max-width: 100%;
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.accountImages img.brandLogo {
    background: #fff;
    border-radius: 100px;
    width: 70px;
    height: 70px;
    object-fit: contain;
}

.adsSystemLogo {
    margin-left: auto;
}

.accountImages img.adsSystemLogo {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
}
