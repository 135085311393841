.AddCampaignForm label {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.AddCampaignForm input[type=text] {
    height: 45px;
    padding-left: 25px;
    border-radius: 8px;
    border: solid 1px #000;
    width: 70%;
    box-sizing: border-box;
}

.AddCampaignForm textarea {
    height: 100px;
    padding: 10px 25px;
    border-radius: 8px;
    border: solid 1px #000;
    width: 70%;
    max-width: 70%;
    box-sizing: border-box;
}

.AddCampaignForm label span {
    width: 30%;
}

.AddCampaignForm .campaignTypeSelect {
    width: 70%;
}
