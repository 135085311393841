.statisticsTable {
    width: 100%;
    background: rgba(255,255,255,0.1);
    padding: 20px;
    border-radius: 12px;
    color: #ddd;
}


.statisticsHeader {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
}

.headerField {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px rgb(255 255 255 / 30%);
    border-right: 0px;
    padding: 10px 20px;
    /*width: 9%;*/
    text-align: center;
    font-weight: 700;
    background: rgba(0, 0, 0, 0.1);
    color: #efefef;
    font-size: 14px;
}
.headerField:last-of-type {
    border-right: solid 1px rgb(255 255 255 / 30%);
}

.singleStatisticsInTable {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%
}

.StatisticsByGroupTable .singleStatisticsInTable {
    cursor: pointer;
}

.StatisticsByGroupTable .singleStatisticsInTable:hover {
    background: rgba(255,255,255,.1);
}

.statsField {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: solid 1px rgb(255 255 255 / 30%);
    border-top: 0px;
    border-right: 0px;
    /*width: 9%;*/
    color: #ddd;
    font-size: 14px;
}

.statsField:last-of-type {
    border-right: solid 1px rgb(255 255 255 / 30%);
}

.removeStatisticsElement {
    background: transparent;
    color: #FF6B6B;
    cursor: pointer;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    font-size: 32px;
    padding: 0px;
    line-height: 0.6em;

}

.noElementsInStatisticTable {
    font-size: 20px;
    text-align: center;
    border: solid 1px rgb(255 255 255 / 30%);
    padding: 40px 0px;
    border-top: 0;
    color: #ddd;
}

.addStatisticsButton {
    margin-bottom: 12px;
}

/* width headers and fields by type */
/* direct searchAndBanners */
.searchAndBannerHeader .headerField {
    width: calc(100% / 10);
}
.DirectSearchAndBanners .statsField {
    width: calc(100% / 10);
}

.isAdmin .searchAndBannerHeader .headerField {
    width: calc(100% / 11);
}
.isAdmin .DirectSearchAndBanners .statsField {
    width: calc(100% / 11);
}


/* media and vk */
.DirectMedia .statsField,
.VK .statsField {
    width: calc(100% / 7);
}
.mediaAndVkHeader .headerField {
    width: calc(100% / 7);
}

.isAdmin .DirectMedia .statsField,
.isAdmin .VK .statsField {
    width: calc(100% / 8);
}
.isAdmin .mediaAndVkHeader .headerField {
    width: calc(100% / 8);
}

.StatisticsByGroupTable,
.StatisticsByAdsTable {
    background: rgba(255,255,255,.1);
    border-radius: 12px;
    padding: 20px;
}



.statsField .singleMediaAds {
    width: 100%;
    padding: 0;
    background: transparent;
    margin-bottom: 0;
}

.statsField .singleMediaAds video {
    width: 100%;
    object-fit: cover;
    height: 90px;
}

.statsField .imageElement .AdvImage img {
    height: 90px;
    width: 100%;
    object-fit: cover;
}

.statsField .singleVkAds {
    width: 100%;
    padding: 0;
    background: transparent;
    flex-direction: column;
}

.statsField .singleVkAdsLeft {
    width: 100%;
}

.statsField .singleVkAdsRight {
    width: 100%;
}

.statsField .singleVkAdsRight .singleAdsElement {
    width: 100%;
    margin-top: 12px;
}

.statsField .singleVkAdsRight .AdvVideo video {
    width: 100%;
}

.statsField .singleAdsElement.imageElement.logoElement {
    display: none;
}

/*
** Tablet
*/

@media screen and (max-width: 1024px) {
    .statisticsHeader {
        width: 1000px;
    }

    .statisticsRows {
        width: 1000px;
    }

    .statisticsTable {
        overflow: auto;
    }

}
