.grafik {
    width: 100%
}

.grafiksContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.singleGrafik {
    width: calc(50% - 10px);
    padding: 10px 10px;
    box-sizing: border-box;
    background: rgba(255,255,255,0.1);
    border-radius: 12px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.singleGrafik:nth-last-of-type(1),
.singleGrafik:nth-last-of-type(2){
    margin-bottom: 0px;
}

.singleGrafik:nth-child(2n) {
    margin-right: 0;
}


/*
** Tablet
*/

@media screen and (max-width: 1024px) {

    .singleGrafik {
        width: 100%;
        margin-right: 0px;
    }

    .singleGrafik:nth-last-of-type(1), .singleGrafik:nth-last-of-type(2) {
        margin-bottom: 20px;
    }
}
