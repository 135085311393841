.statisticsFilter {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
}

.singleFilter {
    margin-right: 12px;
}

.singleFilter input {
    padding: 10px 12px;
    border-radius: 4px;
    border: none;
    background: rgba(255,255,255,0.1);
    color: #ddd;
}

.singleFilter .react-datepicker {
    background: rgba(255,255,255,0.1);
    backdrop-filter: blur(12px);
}

.singleFilter .react-datepicker__header {
    background: rgba(255, 255,255,0.3);
}

.singleFilter .react-datepicker__current-month,
.singleFilter .react-datepicker-time__header,
.singleFilter .react-datepicker-year-header {
    color: #efefef;
}

.singleFilter .react-datepicker__day-name,
.singleFilter .react-datepicker__day,
.singleFilter .react-datepicker__time-name {
    color: #ddd;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background: #16222A;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background: #3A6073;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    background: #3A6073;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    border-color: #16222A;
    border-color: #ddd;
}

.react-datepicker__navigation:hover *::before {
    border-color: #3A6073;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background: #3A6073;
}

/*
** Mobile
*/

@media screen and (max-width: 768px) {
    .statisticsFilter {justify-content: space-between;}

    .singleFilter {
        width: 48%;
        margin-right: 0;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .singleFilter input {
        width: 100%;
    }

}









