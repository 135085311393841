.accountsList {
    margin-right: auto;
}

.userPage .accountsList {
    margin-right: 30px;
}

.addAccountBtn {
    width: 100%;
    font-size: 20px;
    margin-top: 20px;
}

.accountsListContainer {
    overflow: auto;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 12px;
}
