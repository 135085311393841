.parameterHeading {
    font-size: 24px;
    color: #fff;
}

.parameterValue {
    color: #fff;
    background: rgba(255,255,255,.1);
    border-radius: 12px;
    padding: 15px 30px;
    margin-top: 12px;
    margin-bottom: 30px;
}

.singleParameter.parameterAge,
.singleParameter.parameterSex {
    max-width: calc(50% - 15px);
}

.singleParameter.parameterSex {
    margin-left: auto;
}

.groupSettings {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.singleParameter {
    width: 100%;
}
