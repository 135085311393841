.singleSearchAds {
    background: rgba(255,255,255,0.1);
    border-radius: 12px;
    padding: 15px 30px;
    margin-bottom: 30px;
    width: calc(50% - 15px);
    color: #fff;
}

.singleMediaAds {
    background: rgba(255,255,255,0.1);
    border-radius: 12px;
    padding: 15px 30px;
    margin-bottom: 30px;
    width: calc(50% - 15px);
    color: #fff;
}

.singleAdsElement span {
    font-weight: bold;
}

.singleAdsElement {
    margin-bottom: 12px;
    width: 100%;
}

.singleAdsElement:last-of-type {
    margin-bottom: 0;
}

.singleAdsElement .AdvVideo {
    margin-top: 12px;
}

.adsListByGroup {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

.singleAdsElement a {
    color: #fff;
    overflow-wrap: break-word;
}

.singleAdsElement.imageElement {
    width: 50%;
    display: block;
}

.singleAdsElement.videoElement {
    width: calc(50% - 15px);
}

.popupMessageWithComponent .videoElement .AdvVideoContainer video {
    height: 140px;
    width: 100%;
    object-fit: cover;
}

.singleMediaAds {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.singleAdsElement.imageElement img {
    border-radius: 12px;
    width: 100%;
    object-fit: contain;
}

.singleVkAds {
    width: 100%;
    background: rgba(255,255,255,.1);
    border-radius: 12px;
    padding: 15px 30px;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.singleVkAdsLeft {
    width: 50%;
}

.singleVkAdsRight {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.singleVkAdsRight .singleAdsElement {
    width: calc((100% / 3) - 10px);
    margin-bottom: 0;
}

.singleVkAdsRight .singleAdsElement img {
    display: block;
    margin-top: 12px;
    width: 90px;
    height: 90px;
    object-fit: cover;
}

.singleVkAdsRight .AdvVideo video {
    height: 90px;
    width: 90px;
    object-fit: cover;
}

.singleVkAdsRight .VideoPlayIcon img {
    width: 50px;
    height: 50px;
    margin: auto;
}
.VideoPlayIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.AdvVideoContainer {
    position: relative;
}

.AdvVideoContainer .VideoPlayIcon img {
    width: 50px;
    height: 50px;
}

.imageElement .AdvImage img {
    height: 140px;
    width: 100%;
    object-position: top left;
    border-radius: 0;
}

.imageElement .AdvImageContainer {
    padding: 0;
}

.imageElement .AdvImage {
    margin-top: 12px;
}

.singleVkAds .imageElement .AdvImage img {
    height: 90px;
    object-position: top left;
    border-radius: 0;
}

.statsField .singleSearchAds {
    padding: 0;
    width: 100%;
    background: transparent;
    font-size: 12px;
    margin-bottom: 0;
}

.AdvImageContainer img {
    display: block;
    margin: auto;
}
