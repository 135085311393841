.DataTableDataAdd {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
}

.DataTableDataAdd label {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.DataTableDataAdd input[type=text],
.DataTableDataAdd input[type=number] {
    height: 45px;
    padding-left: 25px;
    border-radius: 8px;
    border: solid 1px #000;
    width: 70%;
    box-sizing: border-box;
}

.DataTableDataAdd input:disabled {
    background: #e9e9e9;
}

.DataTableDataAdd label span {
    width: 30%;
}

.DataTableDataAdd label .react-datepicker-wrapper {
    width: 70%;
}

.DataTableDataAdd label .react-datepicker-wrapper input {
    width: 100%;
}

.buttonFormAdd {
    width: 30%;
    margin: auto;
}


/*
** Mobile
*/

@media screen and (max-width: 768px) {

    .DataTableDataAdd label {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .DataTableDataAdd label .react-datepicker-wrapper {
        width: 100%;
    }

    .DataTableDataAdd input[type=text], .DataTableDataAdd input[type=number] {
        width: 100%;
        margin-top: 12px;
    }

    .DataTableDataAdd label span {
        width: 100%;
    }

    .buttonFormAdd {
        width: 50%;
    }
}
