.campaignsList {
    margin-right: 30px;
}

.groupsListContainer {
    overflow: auto;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 12px;
}

.SingleGroup.listElement {
    flex-direction: row;
    flex-wrap: wrap;
}

.groupName {
    width: 100%;
}

.singleGroupLinks {
    margin-top: 20px;
    margin-left: 20px;
    text-decoration: underline;
    line-height: 1.6em;
    cursor: pointer;
}
