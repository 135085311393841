.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popupBackground {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    background: rgba(22,34,42,0.5);

    backdrop-filter: blur(12px);
}

.popupContent {
    background: rgba(255,255,255,0.5);
    z-index: 1;
    width: 100%;
    max-width: 500px;
}

.popupHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-bottom: solid 3px #16222A;
    height: 50px;
    box-sizing: border-box;
    background: #3a6073;
}

.popupMessag {
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding: 0px 30px;
}

.popupHeading {
    width: calc(100% - 50px);
    padding-left: 30px;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 500;
    color: #efefef
}

.popupClose {
    width: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #16222A;
    height: 50px;
    cursor: pointer;
}

.popupClose img {
    width: 50%;
}

.popupWithContent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popupContentWithComponent {
    background: rgba(255,255,255,0.5);
    z-index: 1;
    width: 100%;
    max-width: 800px;
    max-height: 90vh;
}

.popupMessageWithComponent {
    max-height: calc(90vh - 50px);
    overflow: auto;
    padding: 30px 30px;
}

.popupContentWithComponent.isFilledBg {
    background: #3a6073;
}

.popupContainer .AdvVideoContainer video {
    max-width: 100%;
}

/*
** Tablet
*/

@media screen and (max-width: 1024px) {

    .popupContentWithComponent {
        width: 100%;
        max-width: 80%;
    }
}


/*
** Mobile
*/

@media screen and (max-width: 768px) {
    .popupContainer {
        height: 100%;
        display: block;
    }

}
