.LoginPage {
    display: flex;
    align-items: center;
    justify-content: center;
}

h1 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
}

.auth {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: rgba(255,255,255,0.5);
    background: #16222A;
    padding: 40px 20px;
    border-radius: 12px;
    margin: auto;
    width: 100%;
    max-width: 500px;
    overflow: hidden;
}

.loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 300px;
    margin: auto;
}

.loginForm label {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: auto;
    color: #ddd;
    margin-bottom: 20px;
}

.loginForm input {
    width: 100%;
    height: 45px;
    padding-left: 25px;
    border-radius: 8px;
    border: solid 1px #3A6073;
    box-sizing: border-box;
    margin-top: 8px;
}

.loginForm button {
    font-size: 16px;
    width: 100%;
    margin-top: 12px;
}

.loginLinksContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 300px;
    margin: auto;
    margin-top: 40px;
}

.loginLinksContainer a {
    color: #ddd;
}

.errorField {
    color: #ff3535;
    margin-top: 30px;
}
