.StatisticsTotal {
    width: 100%;
}

.StatisticsContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 10px;
}

.singleStatisticsTotal {
    width: calc(20% - 20px);
    background: rgba(255,255,255,0.1);
    border-radius: 12px;
    color: #ddd;
    padding: 10px 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.singleStatisticsTotal:last-of-type {
    margin-right: 0px;
}

.singleStatisticsTotalLeft {
    width: 70%
}

.singleStatisticsTotal * {
    cursor: default;
}

.totalName {
    font-size: 18px;
    color: #efefef;
    font-weight: 600;
    margin-bottom: 12px;
}

.totalValue {
    font-size: 16px;
    color: #ddd;
}

.totalIcon {
    width: 50px;
    margin-left: auto;
}

/*
** Tablet
*/

@media screen and (max-width: 1024px) {
    .singleStatisticsTotal {
        width: calc(30% - 20px);
        margin: 0px 10px 20px 10px;
    }

    .StatisticsContainer {
        align-items: center;
    }

    .totalName {
        height: 45px;
    }

    .singleStatisticsTotal:last-of-type {
        margin-right: 10px;
    }
}


/*
** Mobile
*/

@media screen and (max-width: 768px) {
    .singleStatisticsTotal {
        width: calc(50% - 10px);
        margin: 0px 5px 10px 5px;
    }

    .totalIcon {
        width: 36px;
    }

    .totalName {
        height: auto;
        font-size: 14px;
    }
}
