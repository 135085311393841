.AddAccountForm label {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.AddAccountForm input[type=text] {
    height: 45px;
    padding-left: 25px;
    border-radius: 8px;
    border: solid 1px #000;
    width: 70%;
    box-sizing: border-box;
}

.AddAccountForm label span {
    width: 30%;
}

.AddAccountForm .accountTypeSelect {
    width: 70%;
}
