.campaignsList {

}

.addCampaignBtn {
    width: 100%;
    font-size: 20px;
    margin-top: 20px;
}

.campaignsListContainer {
    overflow: auto;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 12px;
}
