@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;500;600;700;800;900&display=swap');
* {
    box-sizing: border-box;
    font-family: 'Exo 2', sans-serif;
}
.container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1500px;
    width: 100%;
    margin: auto;
}

h1 {
    font-size: 32px;
    width: 100%;
    color: #efefef
}

h2 {
    color: #efefef;
}

.pageWrapper {
    height: 90vh;
    padding: 30px;
    overflow: auto;
    box-sizing: border-box;
}

.fullPageBody {
    background: linear-gradient(180deg, #3A6073, #16222A);
}

.heading {
    font-size: 20px;
    font-weight: 600;
    margin: auto;
    text-align: center;
    margin-bottom: 12px;
    width: 100%;
    margin-top: 0px;
    color: #efefef;
}

.hoverStyleButton {
    background: #3A6073;
    color: #fff;
    border: none;
    border-radius: 12px;
    padding: 20px 20px;
    transition: .2s;
    cursor: pointer;
}

.hoverStyleButton:hover {
    background: #16222A;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.5);
}

/*ScrollBar CSS*/
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: rgba(255, 255, 255, 0.1) transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    border: none;
    transition: .1s;
    height: 3px;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.2);
}


/*
** Mobile
*/

@media screen and (max-width: 768px) {
    h1 {
        font-size: 24px;
    }
}
